:root {
	/* LIGHT MODE */
	--body_background: #ffffff;
	--text_color: #000000;
	--border: #000000;
	--blog_background: #cccccc;
	--card_bg: #dfdfdf;
	--img_color: brightness(0%) saturate(0%) contrast(1000%);
}
[data-theme="dark"] {

	/* DARK MODE */
	--body_background: #1c1c1c;
	--text_color: #ffffff;
	--border: #ffffff;
	--blog_background: #222222;
	--card_bg: #dfdfdf;
	--img_color: brightness(100%) saturate(0%) contrast(1000%);

}

/* background 1c1c1c
color fff */

/* * {
	transition: 0.1s ease-in-out;
} */

html,
body {
	margin: 0;
	/* height: 100vh; */
	width: 100vw;
	overflow-x: hidden;
    position: relative;
	color: var(--text_color);

}

body {
	font-family: "Roboto", sans-serif;
	background-color: var(--body_background);
}

u {
	color: var(--border);
}

h1 {
	margin: 20px 0;
}
/* p {
	color: #fff;
} */

.center { 
	text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
}

.top-name {
    font-size: calc(1.375rem + 1.2vw);
	color: var(--text_color);
    font-weight: bold;
	margin-top: 3rem;
  }

.top-name2 {
    color: var(--text_color);
	font-weight: 200;
  }
  

    
.main-para {
    text-align: initial;
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
    }

@media screen and (max-width: 768px) {
    .main-para {
        width: 100%;
        }
    .center {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    }

.nav-wrapper {
	display: flex;
	position: relative;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	margin: auto;
	width: 90%;
	height: 80px;
	/* border-radius: 0 0 15px 15px; */
	padding: 0 25px;
	z-index: 2;
	background: var(--body_background);
    border-bottom: 1px solid var(--border);
	/* box-shadow: 0 1px 2px rgba(255, 255, 255, 0.2); */
}

.nav-wrapper-foot {
	display: flex;
	position: fixed;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 90%;
    bottom: 0;
	height: 80px;
	/* border-radius: 0 0 15px 15px; */
    /* border-top: 1px solid rgb(255, 255, 255); */
	padding: 0 25px;
	z-index: 0;
    height: 70px;
	background: var(--body_background);
	/* box-shadow: 0 1px 2px rgba(255, 255, 255, 0.2); */
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	height: 50px;
	cursor: pointer;
}

.logo_2 {
	height: 20px;
	cursor: pointer;
}

.logo-foot {
    text-align: center;
    justify-content: center;
	height: 10px;
}

.nav-tabs {
	display: flex;
    color: #4d1b1b;
	/* font-weight: 600; */
	font-size: 15px;
	list-style: none;
	text-decoration: none;
}

.nav-tab:not(:last-child) {
	padding: 10px 25px;
	margin: 0;
}

.nav-tab:last-child {
	padding: 10px 0 0 25px;
}

.nav-tab,
.menu-btn {
	cursor: pointer;
	text-decoration: none;
	color: var(--text_color);
}

.foot {
    position: absolute;
    bottom: 0;
    padding-top: 2rem;
}



.hidden {
	display: none;
}

@media screen and (max-width: 800px) {
	.nav-container {
		position: fixed;
		display: none;
		overflow-y: auto;
		z-index: -1;
		top: 0;
		right: 0;
		width: 280px;
		height: 100%;
		background: #747474;
		box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
		z-index: 999;
	}

	.nav-tabs {
		flex-direction: column;
		align-items: flex-end;
		margin-top: 80px;
        color: #000000;
		width: 100%;
	}

	.nav-tab:not(:last-child) {
		padding: 20px 25px;
		margin: 0;
		border-right: unset;
		border-bottom: 1px solid #1c1c1c;
	}

	.nav-tab:last-child {
		padding: 15px 25px;
	}

	.menu-btn {
		position: relative;
		display: block;
		margin: 0;
		width: 20px;
		height: 15px;
		cursor: pointer;
		z-index: 2;
		padding: 10px;
		border-radius: 10px;
	}

	.menu-btn .menu {
		display: block;
		width: 100%;
		height: 2px;
		border-radius: 2px;
		background: var(--text_color);
	}

	.menu-btn .menu:nth-child(2) {
		margin-top: 4px;
		opacity: 1;
	}

	.menu-btn .menu:nth-child(3) {
		margin-top: 4px;
	}

	#menuToggle:checked + .menu-btn .menu {
		transition: transform 0.2s ease;
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(1) {
		transform: translate3d(0, 6px, 0) rotate(45deg);
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(2) {
		transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
		opacity: 0;
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(3) {
		transform: translate3d(0, -6px, 0) rotate(-45deg);
	}

	#menuToggle:checked ~ .nav-container {
		z-index: 1;
		display: flex;
		animation: menu-slide-left 0.3s ease;
	}
	@keyframes menu-slide-left {
		0% {
			transform: translateX(200px);
		}
		to {
			transform: translateX(0);
		}
	}
}



/* -----------blog css-------------- */


.blog_post {
	background: var(--blog_background);
	max-width: 900px;
	border-radius: 10px;
	box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 3rem;
	margin-bottom: 2rem;
  }
  
  
  .container_copy {
	padding: 2rem 2rem 2rem 2rem;
  }
  
  .container_copy2 {
	padding: 1rem 2rem 0rem 2rem;
  }
  
  /* .img_pod {
	height: 110px;
	width: 110px;
	background: linear-gradient(90deg, #ff9966, #ff5e62);
	z-index: 10;
	box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
	border-radius: 100%;
	position: absolute;
	left: -10%;
	top: -13%;
	display: flex;
	align-items: center;
	justify-content: center;
  }
   */

   /* img {
	height: 8.3rem;
	width: 8.3rem;
	position: relative;
	border-radius: 100%;
	box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
	z-index: 1;
  } */
  
  .blog-date {
	margin: 0 0 0.5rem 0;
	color: var(--text_color);
	font-size: 1rem;
  }
  
  .blog-title {
	margin: 0 0 1rem 0;
	font-size: 1.5rem;
	letter-spacing: 0.5px;
	color: var(--text_color);
  }
  
  .blog-content {
	margin: 0 0 0 0;
	font-size: 1rem;
	line-height: 1.45;
	color: var(--text_color);
  }
  
  /* .btn_primary {
	border: none;
	outline: none;
	background: linear-gradient(90deg, #ff9966, #ff5e62);
	padding: 1.5rem 2rem;
	border-radius: 50px;
	color: white;
	font-size: 1.2rem;
	box-shadow: 1px 10px 2rem rgba(255, 94, 98, 0.5);
	transition: all 0.2s ease-in;
	text-decoration: none;
  }
  
  .btn_primary:hover {
	box-shadow: 0px 5px 1rem rgba(255, 94, 98, 0.5);
  } */
  
  .container_copy2:hover {
	box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 40px 0px;
	transition-duration: 0.3s;
  } 
  
  @media only screen and (max-width: 650px) {
	body {
	  /* background-color: black; */
	}
  }


  .content-contain {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
  }

  .img_limit img {
	max-height: 150px;
  }

.about-me{
	margin-left: 5rem; 
}

.about-container {
	font-size: 18px;
	margin-left: 5rem; 
	margin-right: 5rem;
}


@media only screen and (max-width: 768px) {
	.about-container {
		margin-left: 2rem; 
		margin-right: 2rem;
	}
	.about-me {
		margin-left: 2rem; 
	}
  }

  .contact_me {
    font-size: calc(1.375rem + 1vw);
	color: var(--text_color);
    font-weight: bold;
	margin-top: 5rem;
	margin-left: 20px; 
	margin-right:20px
  }

  .contact_me_items {
    text-decoration: none; 
	color: var(--text_color);
  }



/* ------------ICONS for dark and light mode---------------- */
.dark_mode {
    margin-top: -20px;
    margin-left: 10px;
}

.dark_mode_label {
    width: 65px;
    height: 30px;
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    /* transition: 0.3s; */
}
.dark_mode_label:after {
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: 180px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}
.dark_mode_input {
    width: 0;
    height: 0;
    visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
    background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
    left: 62px;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #5a5050);
}
.dark_mode_label:active:after {
    width: 30px;
}

.dark_mode_label svg {
    position: absolute;
    width: 20px;
    top: 5px;
    z-index: 100;
}
.dark_mode_label svg.sun {
    left: 5px;
    fill: #fff;
	z-index: 1;
    transition: 0.3s;
}
.dark_mode_label svg.moon {
    left: 40px;
    fill: #7e7e7e;
	z-index: 1;
    transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
    fill: #aaaaaa;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
    fill: #fff;
}


.img_test{
	filter: var(--img_color);
}

.resume_container {
	margin-left: 5rem;
	margin-right: 5rem;
}

@media only screen and (max-width: 850px) {
	.resume_container {
		margin-left: 1rem; 
		margin-right: 1rem;
	}
	.same_line{
		float: inline-start;
	}
  }


.resume-title {
	margin-top: 3rem;
	border-bottom: 1px solid var(--border);
}

.resume-sub-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 3rem;

}


@media only screen and (max-width: 850px) {
	.resume-sub-content {
		flex-direction: column;
		justify-content: space-between;
	}
	.resume-sub-content {
		align-items: start;
	}
	
  }

.sub-title {
	font-weight: bold;
}

.sub-title2 {
	margin-top: 0px;
}

.right-sub-title {
	font-weight: bold;
	margin-top: 0%;
	margin-bottom: 0%;
}

.def_link {
	color: var(--text_color);
}


/*  ================ portfoloio card ======================== */



/* .portfolio_container {
	margin-top: 500px;
}
 */

.img_container {
	position: relative;
	display: flex;
	justify-content: center;
	/* align-items: center; */
	flex-wrap: wrap;
	gap: 100px 150px;
	padding: 100px 0px;
}

.img_container .img_card {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 300px;
	height: 300px;
	background: var(--card_bg);
	border-radius: 20px;
	/* box-shadow: 0 35px 80px rgba(0,0,0,0.15); */
	transition: all 0.5s;
}

.img_container .img_card:hover {
	height: 400px;
}

.img_container .img_card .img_box {
	position: absolute;
	top: 20px;
	width: 270px;
	height: 220px;
	background: #333;
	border-radius: 12px;
	overflow: hidden;
	transition: 0.5s;
}

.img_container .img_card:hover .img_box {
	top: -100px;
	scale: 0.75;
	/* box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2); */
}

.img_container .img_card .img_box img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.img_container .img_card .img_content {
	position: absolute;
	top: 235px;
	width: 100%;
	/* padding: 0 30px; */
	height: 50px;
	overflow: hidden;
	text-align: center;
	transition: 0.3s;
}

.img_container .img_card:hover .img_content {
	top: 130px;
	height: 250px;
}

.img_container .img_card .img_content h2 {
	font-size: 1.5rem;
	font-weight: 700;
	color: #000;
}

.img_container .img_card .img_content p {
	color: #333;
}

.img_container .img_card .img_content a {
	position: relative;
	top: 15px;
	display: inline-block;
	padding: 12px 25px;
	color: #333;
	font-weight: 500px;
	text-decoration: none;
	border-radius: 8px;
}
